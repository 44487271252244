<template>
  <div class="bulk-actions">
    <div class="bulk-actions__container">
      <h2>Style Bulk Creation</h2>
      <StyleActions />
    </div>
  </div>
</template>

<script>
import StyleActions from '@/components/style/StyleActions'

export default {
  name: 'BulkActions',

  components: { StyleActions },

  data: () => ({}),

  methods: {}
}
</script>

<style lang="scss" scoped>
.bulk-actions {
  height: 100%;
}

.bulk-actions__container {
  height: 100%;
  padding: spacing(6) spacing(4);
  overflow-y: scroll;
}

.bulk-actions__container::-webkit-scrollbar {
  display: none;
}

.bulk-actions__container h2 {
  margin-bottom: spacing(2.5);
}

.bulk-actions__card {
  margin-bottom: spacing(4);
  padding: spacing(2);

  h1 {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(2);
  }

  .bulk-actions__list-main {
    @include text-title-small;
  }

  .bulk-actions__list-sub {
    @include text-body;

    margin: spacing(1) 0;
    padding-left: spacing(2);
    list-style: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
