<template>
  <div class="bulk-validation">
    <div
      v-if="invalidCodes.length > 0"
      class="bulk-validation__invalid-codes"
    >
      <p>
        Note: The following PLM codes cannot be created:
        <br>
        {{ invalidCodes.join(', ') }}
      </p>
    </div>
    <ElementTable
      :data="plmData"
      border
      stripe
      class="bulk-validation__table"
      :cell-style="{ height: '55px' }"
      empty-text="Data not found."
    >
      <ElementTableColumn
        label="PLM Code"
        prop="plmCode"
      />

      <ElementTableColumn
        label="Season"
        prop="season"
      >
        <template slot-scope="scope">
          {{ scope.row.season.name }}
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Vendor Company"
        prop="vendorCompany"
      >
        <template slot-scope="scope">
          <ElementSelect
            v-model="scope.row.vendorCompany"
            size="mini"
            @change="setStyleStatus(scope.row)"
          >
            <ElementOption
              v-for="vendor in vendors"
              :key="`${vendor.name}`"
              :label="vendor.name"
              :value="vendor.id"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Status"
        prop="status"
      >
        <template slot-scope="scope">
          <ElementSelect
            v-model="scope.row.status"
            size="mini"
          >
            <ElementOption
              v-for="vendor in [ASSET_STATUS.CREATED, ASSET_STATUS.REQUESTED]"
              :key="`${vendor.action}`"
              :label="vendor.action"
              :value="vendor.value"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>
    </ElementTable>
    <ElementButton
      type="primary"
      class="bulk-validation__creation-button"
      :loading="isLoading"
      @click="createStyles"
    >
      Create Styles
    </ElementButton>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { ASSET_STATUS } from '@/constants/assetStatuses'
import { mapActions } from 'vuex'

export default {
  name: 'StyleBulkValidation',

  props: {
    validCodes: VueTypes.array,
    invalidCodes: VueTypes.array,
    season: VueTypes.object,
    vendors: VueTypes.array
  },

  data () {
    return {
      isLoading: false,
      ASSET_STATUS,
      plmData: []
    }
  },

  watch: {
    /**
     *
     */
    validCodes () {
      this.initPlmData()
    }
  },

  methods: {
    ...mapActions(['stylesBulkCreation']),

    /**
     * @param {object} row
     */
    setStyleStatus (row) {
      const index = this.plmData.findIndex(item => item.plmCode === row.plmCode)
      this.plmData[index].status = ASSET_STATUS.REQUESTED.value
    },

    /**
     */
    async createStyles () {
      this.isLoading = true

      const styles = await this.stylesBulkCreation({
        groupId: this.$route.query.group_id,
        data: this.processPlmData(this.plmData)
      })

      this.isLoading = false

      this.$emit('set-created-styles', {
        styles
      })
    },

    /**
     */
    initPlmData () {
      this.plmData = this.validCodes.map(code => {
        return {
          plmCode: code,
          status: ASSET_STATUS.CREATED.value,
          vendorCompany: null,
          season: this.season
        }
      })
    },

    /**
     * @param   {Array}    data
     *
     * @returns {object[]}
     */
    processPlmData (data) {
      return data.map(item => {
        return {
          plm_code: item.plmCode,
          season_id: item.season.id,
          status: item.status,
          vendor_company_id: item.vendorCompany
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bulk-validation {
  margin: spacing(4) 0 spacing(2) 0;
}

.bulk-validation__table {
  margin-bottom: spacing(2);
}

.bulk-validation__creation-button {
  float: right;
  margin-bottom: spacing(2);
}

.bulk-validation__invalid-codes {
  margin-bottom: spacing(2);
}

.bulk-validation__invalid-codes p {
  color: $red;
  font-size: spacing(1.5);
}
</style>
